<template>
  <div class="pump-view ly-wrap">
    <div class="currency-title"><span class="back" @click="goWay('/')"></span>泵站工艺</div>

    <div class="error-wrap" v-if="isError">
      <div class="error-img"></div>
      <p>{{ errorMsg || '系统异常' }} </p>
    </div>

    <div class="ly-center" v-else>
      <div class="pump-title">
        <div class="icon"></div>
        <div class="txt">
          <div class="name">河龙口泵站</div>
          <div class="time">{{ collectTime }}</div>
        </div>
        <div class="rt-switch" @click="goWay('/pump/view')">切换泵站</div>
      </div>
      <div class="view-bg">
        <div class="top-wrap top-hlk">
          <!--仪器仪表-->
          <div class="meter-wrap">
            <div class="ab p-red red-1" v-if="pumpViewData.P1_RUN.data == '0' "></div>
            <!--            <div class="ab p-red red-1"></div>-->
          </div>

          <!--文本-->
          <div class="txt-wrap">
            <div class="ab tt-style to-1">
              <div class="num">{{ pumpViewData.LT001.data || '--' }}{{ pumpViewData.LT001.unit }}</div>
              <div class="name">液位</div>
              <div class="point"></div>
            </div>
            <!--            <div class="ab tt-style to-2">-->
            <!--              <div class="num">{{ pumpViewData.PT001.data || '&#45;&#45;' }}{{ pumpViewData.PT001.unit }}</div>-->
            <!--              <div class="name">出口压力</div>-->
            <!--              <div class="point"></div>-->
            <!--            </div>-->
            <div class="ab tt-style to-9">
              <div class="line-name"><span>取水泵</span></div>
              <div class="line">运行状态:<span class="normal">{{ pumpViewData.P1_RUN.data == "0" ? '停止' : '启动' }}</span>
              </div>
              <div class="line">运行电流:<span class="normal">{{
                  pumpViewData.P1_CURRENT.data || '--'
                }}{{ pumpViewData.P1_CURRENT.unit }}</span></div>
              <div class="line">运行电压:<span class="normal">{{
                  pumpViewData.P1_VOLTAGE.data || '--'
                }}{{ pumpViewData.P1_VOLTAGE.unit }}</span></div>
              <div class="line">运行时间:<span class="normal">{{
                  pumpViewData.P1_RUNTIME.data || '--'
                }}{{ pumpViewData.P1_RUNTIME.unit }}</span></div>
              <div class="point"></div>
            </div>
          </div>
          <!--else-->
          <div class="else-wrap">
            <div :class="[pumpViewData.alarm.data=='200'?'':'warning','normal-box ab']"
                 @click="goWay('/pump/alert')" v-track-event="'工艺报警, 进入'">
            </div>
            <!--视频监控-->
            <div class="ab video-box" @click="goWay('/pump/video')" v-track-event="'工艺视频监控, 进入'"></div>
            <!--数据查询-->
            <div class="ab data-box" @click="goWay('/pump/real')" v-track-event="'工艺数据查询, 进入'"></div>

            <!--时间清零-->
            <div class="ab time-box">
              <div class="time-btn" v-track-event="'工艺1#泵运行时间清零, 点击'"
                   @click="timeClear(pumpViewData.P1_RUNSET0.id,pumpViewData.P1_RUNTIME.data,'1#泵')">
                运行时间清零
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-wrap">
        <div class="bottom-item">
          <div class="b-name" style="border-bottom: 1px dashed #1890FF;">1#泵</div>
          <div class="b-info">
            <div class="i-title">
              <div class="f-left">状态</div>
              <div class="f-right">控制</div>
            </div>
            <div class="i-info">
              <div class="f-left">
                <div class="line" :class="[pumpViewData.P1_RUN.data=='0'?'red':'green']"
                     style="border-bottom: 1px dashed #1890FF;">{{ pumpViewData.P1_RUN.data == '0' ? '停止' : '运行' }}
                </div>
                <div class="line" :class="[pumpViewData.P1_REMOTE.data=='0'?'red':'green']">
                  {{ pumpViewData.P1_REMOTE.data == '0' ? '就地' : '远程' }}
                </div>
              </div>
              <div class="f-right">
                <div class="btn-item"
                     @click="handleStart(pumpViewData.P1_HMISTART.id,pumpViewData.P1_RUN,'1#泵')">启动
                </div>
                <div class="btn-item"
                     @click="handleStop(pumpViewData.P1_HMISTOP.id,pumpViewData.P1_RUN,'1#泵')">停止
                </div>
                <div class="btn-item"
                     @click="handleReset(pumpViewData.P1_HMIREST.id,pumpViewData.P1_HMIREST,'1#泵')">复位
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-copyright">- 技术支持：云昇新控 -</div>

    </div>
  </div>
</template>

<script>

import {
  getCurveData,
  getPumpListByPumpId,
  postPumpView,
  postRemoteControl,
  getPumpView,
  postVariableData
} from '../../api/api.js'
import {getSelectPump, getUserInfo} from '@/utils/auth'
import store from '../../store/index.js'
import moment from "moment";
import {MessageBox, Toast} from 'mint-ui';

export default {
  name: 'PumpView',
  components: {},
  filters: {
    formatData(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  computed: {
    // pumpViewData() {
    //   return this.$store.state.sundries.pumpViewData.data || this.pumpInitData
    // },
    // loadTime() {
    //   return this.$store.state.sundries.pumpViewData.collectTime
    // }
  },
  data() {
    return {
      pumpSwitch: 'left',
      isError: false,// 异常
      errorMsg: '',// 异常
      pumpId: '1678679215925895170',// 泵站ID
      viewData: {},
      zbxpData: [],// 主备选择的初始化数据
      pumpList: [],
      timer: null,
      interval: 5000,
      collectTime: '',
      // pumpViewData:[],
      pumpViewData: {
        "P1_FAULT": {
          "id": "1678662195054485506",
          "name": "P1_FAULT",
          "data": "0",
          "unit": null,
          "zhName": "1号泵故障"
        },
        "P1_RUNSET0": {
          "id": "1678662803861905409",
          "name": "P1_RUNSET0",
          "data": "0",
          "unit": null,
          "zhName": "1号泵运行时间置零"
        },
        "P1_RUNTIME": {
          "id": "1678662533924888578",
          "name": "P1_RUNTIME",
          "data": "0",
          "unit": "h",
          "zhName": "1号泵运行时间"
        },
        "P1_HMISTOP": {
          "id": "1678663120359890946",
          "name": "P1_HMISTOP",
          "data": "0",
          "unit": null,
          "zhName": "1号泵启动远程停止"
        },
        "P1_CURRENT": {
          "id": "1678663281362444290",
          "name": "P1_CURRENT",
          "data": "0",
          "unit": "A",
          "zhName": "1号泵运行电流"
        },
        "P1_REMOTE": {
          "id": "1678661975553974274",
          "name": "P1_REMOTE",
          "data": "0",
          "unit": null,
          "zhName": "1号泵就地远程"
        },
        "P1_RUN": {
          "id": "1678662120769167362",
          "name": "P1_RUN",
          "data": "0",
          "unit": null,
          "zhName": "1号泵运行"
        },
        "P1_STFAULT": {
          "id": "1678662623922069505",
          "name": "P1_STFAULT",
          "data": "0",
          "unit": null,
          "zhName": "1号泵启动失败"
        },
        "P1_HMISTART": {
          "id": "1678662894261739521",
          "name": "P1_HMISTART",
          "data": "0",
          "unit": null,
          "zhName": "1号泵启动远程启动"
        },
        "P1_HMIREST": {
          "id": "1678958960286695426",
          "name": "P1_HMIREST",
          "data": "0",
          "unit": null,
          "zhName": "1号泵远程复位"
        },
        "alarm": {
          "id": null,
          "name": "alarm",
          "data": "500",
          "unit": "网关异常",
          "zhName": "报警"
        },
        "P1_VOLTAGE": {
          "id": "1678663411964682242",
          "name": "P1_VOLTAGE",
          "data": "0",
          "unit": "V",
          "zhName": "1号泵运行电压"
        },
        "LT001": {
          "id": "1678667142902947841",
          "name": "LT001",
          "data": "1.57",
          "unit": "m",
          "zhName": "取水池液位"
        },
        "LT001_FAULT": {
          "id": "1678667284569759745",
          "name": "LT001_FAULT",
          "data": "0",
          "unit": null,
          "zhName": "取水池液位故障"
        }
      },

    }
  },
  mounted() {
    this.loadBase()
  },
  created() {
    this.setTimer()
  },
  methods: {
    async loadBase() {
      // const selectPump = await getSelectPump()
      // const userInfo = await getUserInfo()
      const body = {
        pumpId: "1678679215925895170",
        // pumpId: selectPump.id,
        // userId: userInfo.id // socket需要
      }
      this.isError = false
      getPumpView(body).then(res => {
        this.isError = false

        if (res.data.success) {
          this.pumpId = res.data.result.pumpId
          this.collectTime = res.data.result.collectTime
          this.pumpViewData = res.data.result.data
          console.log(res.data.result.data)
        } else {
          this.isError = true
          this.errorMsg = res.data.message || '异常'
        }
      }).catch((err) => {
        this.isError = true
        this.errorMsg = err || '异常'
      })

      // socket
      // postPumpView(body).then(res => {
      //   this.isError = false
      //   if (res.data.success) {
      //     this.pumpId = res.data.result.pumpId
      //     store.dispatch('PUMP_VIEW_DATA', res.data.result)
      //   } else {
      //     this.isError = true
      //     this.errorMsg = res.data.message || '异常'
      //   }
      // }).catch((err) => {
      //   this.isError = true
      //   this.errorMsg = err || '异常'
      // })
    },

    /** 定时器 */
    setTimer() {
      const _this = this
      this.timer = setInterval(() => {
        _this.loadBase()
      }, _this.interval)
    },
    /** 关闭定时器 */
    closeTimer() {
      clearInterval(this.timer)
    },

    timeClear(id, dataBefore, name) {
      let txt = '是否把' + name + '运行时间置零?'
      MessageBox.close()
      MessageBox.confirm(txt).then(action => {
        this.postData(id, 0, dataBefore, '1678679215925895170', 3)
        Toast('操作成功！');
      }).catch((err) => {
        console.log(err)
      });
    },

    // 开关指示灯控制
    switchLight(variableId, data, dataBefore, name) {
      MessageBox.close()
      // 判断当前状态
      if (data == '0') {
        if (dataBefore == '1') {
          MessageBox.confirm('确定关闭' + name + '?').then(action => {
            this.postData(variableId, data, dataBefore, '1678679215925895170', 2)
          }).catch((err) => {
            console.log(err)
          });
        } else {
          Toast('当前' + name + '已经关闭！');
        }
      } else {
        if (dataBefore == '0') {
          MessageBox.confirm('确定开启' + name + '?').then(action => {
            this.postData(variableId, data, dataBefore, '1678679215925895170', 2)
          }).catch((err) => {
            console.log(err)
          });
        } else {
          Toast('当前' + name + '已经开启！');
        }
      }
    },
    // 启动按钮
    handleStart(variableId, data, name) {
      if (data.data == '0') {
        MessageBox.close()
        MessageBox.confirm('确定启动' + name + '?').then(action => {
          this.firstPost(variableId, 1, data.data, this.pumpId)
          setTimeout(() => {
            this.postData(variableId, 0, data.data, this.pumpId, 1);
          }, 800);
        }).catch((err) => {
          console.log(err)
        });
      } else {
        Toast('当前泵已经启动！');
      }
    },
    //  停止按钮
    handleStop(variableId, data, name) {
      if (data.data == '1') {
        MessageBox.close()
        MessageBox.confirm('确定停止' + name + '?').then(action => {
          this.firstPost(variableId, 1, data.data, this.pumpId)
          setTimeout(() => {
            this.postData(variableId, 0, data.data, this.pumpId, 1);
          }, 800);
        }).catch((err) => {
          console.log(err)
        });
      } else {
        Toast('当前泵已经处于停止状态！');
      }
    },
    // 复位按钮
    handleReset(variableId, data, name) {
      MessageBox.confirm('确定复位' + name + '?').then(action => {
        this.firstPost(variableId, 1, data.data, this.pumpId)
        setTimeout(() => {
          this.postData(variableId, 0, data.data, this.pumpId, 6);
        }, 800);
      }).catch((err) => {
        console.log(err)
      });
    },

    // 统一的脉冲请求 第一次
    firstPost(variableId, data, dataBefore, pumpId) {
      const body = {
        variableId: variableId,
        data: data,
        pumpId: pumpId,
        dataBefore: dataBefore,
        typeCode: '1',
      }
      postVariableData(body)
    },
    // 统一的控制请求
    postData(variableId, data, dataBefore, pumpId, typeCode) {
      const body = {
        variableId: variableId,
        data: data,
        dataBefore: dataBefore,
        pumpId: pumpId,
        typeCode: typeCode,
      }
      postVariableData(body).then(res => {
        this.loadBase()
        Toast('操作成功！');
      })
    },
    // 泵的启动停止
    startStop(msg) {
      let txt = '是否' + (msg.data ? '启动' : '停止') + msg.zhName.substring(0, 3) + '?'
      MessageBox.close()
      MessageBox.confirm(txt).then(action => {
        Toast('操作成功！');
      }).catch((err) => {
        console.log(err)
      });
    },
    goWay(path) {
      this.$router.push({path: path})
    },

  },
  destroyed: function () { // 离开页面生命周期函数
    this.closeTimer()
  },
}
</script>

<style scoped lang="less">
@import '../../assets/less/pumpView.less';

.top-hlk {
  height: 6.3rem;
  background: url("../../assets/img/pump/top-hlk.png") no-repeat center 3.2rem;
  background-size: 7.02rem;
  // 设备状态
  .meter-wrap {

    .red-1 {
      top: 3.56rem;
      left: 3.5rem;
    }

  }

  // 文本框
  .txt-wrap {

    .to-1 {
      top: 2.5rem;
      left: 0.68rem;
    }

    .to-2 {
      top: 2.3rem;
      right: 0.7rem;
    }

    .to-9 {
      top: 0.8rem;
      left: 2.7rem;

    }

  }

  // 其他
  .else-wrap {

    // 时间
    .time-box {
      width: 1.66rem;
      height: 2rem;
      top: 0;
      right: 0.2rem;

      .time-btn {
        width: 1.66rem;
        height: 0.8rem;
        background: #FFFFFF;
        border: 0.04rem solid #1890FF;
        box-shadow: 0 0.04rem 0.04rem 0 rgba(204, 204, 204, 0.28);
        border-radius: 0.1rem;
        box-sizing: border-box;
        font-size: 0.22rem;
        font-weight: bold;
        color: #1890FF;
        text-align: center;
        line-height: 0.6rem;
      }
    }

  }
}

.bottom-wrap {
  height: 6.2rem;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  .bottom-item {
    height: 6.2rem;
    display: flex;
    background: url("../../assets/img/view-bg.png") repeat;
    background-size: 0.5rem;

    .b-name {
      width: 1.16rem;
      height: 6.2rem;
      line-height: 6.2rem;
    }

    .b-info {
      height: 6.2rem;
      flex: 1;

      .f-left {
        width: 2rem;
      }

      .i-title {
        height: 1rem;
        line-height: 1rem;
      }

      .i-info {
        .line {
          height: 2.6rem;
          line-height: 2.6rem;
        }

        .f-right {
          display: block;

          .btn-item {
            margin: 0.6rem auto 0;
            width: 3.4rem;
          }
        }
      }
    }
  }
}
</style>
